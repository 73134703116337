import { User } from '../users/user.class';
import Address from '@/shared/types/address.class';
import { DriversLicense } from '../users/drivers-license.class';
import { UserState } from '../users/user-state.enum';
import { Role } from './role.enum';

export class Registration {
  constructor(
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public state?: UserState,
    public role?: Role,
    public project?: string,
    public company?: string,
    public mobilePhoneNumber?: string,
    public languageCode?: string,
    public driversLicense?: DriversLicense,
    public address?: Address,
    public password?: string,
    public passwordConfirmation?: string,
    public isChecked: boolean = false,
    public token?: string,
  ) {}
}
