














































import Vue from 'vue';
import Component from 'vue-class-component';

import { namespace } from 'vuex-class';
import { LoginResult } from '../api/auth/login-result.class';
import { Login } from '../api/auth/login.class';
import { Registration } from '../api/auth/registration.model';
import { User } from '../api/users/user.class';
import BaseForm from '../components/BaseForm.vue';
import {
  FormElement,
  FormElementOptions,
  FormElementType,
} from '../shared/types/form-element.class';

import Toast from '../shared/types/toast.class';
import { ToastType } from '../shared/types/toast-type.enum';
import { Role } from '../api/auth/role.enum';

const authModule = namespace('auth');
const appModule = namespace('app');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseForm } })
export default class Invited extends Vue {
  loginData: Login = new Login();
  registration: Registration = new Registration();
  error = false;
  user: User;
  showErrorForTermsAndConditions = false;

  formElements: FormElement[][] = [
    [
      new FormElement(
        'firstName',
        'First name',
        new FormElementOptions(),
        'required',
      ),
      new FormElement(
        'lastName',
        'Last name',
        new FormElementOptions(),
        'required',
      ),
    ],
    [
      new FormElement(
        'email',
        'Email',
        new FormElementOptions(
          undefined,
          undefined,
          undefined,
          undefined,
          true,
        ),
        'required|email',
      ),
      new FormElement('mobilePhoneNumber', 'Phone', new FormElementOptions()),
    ],
    // [
    //   new FormElement(
    //     'driversLicense.front',
    //     'I.D./drivers license (Front)',
    //     new FormElementOptions(),
    //     '',
    //     FormElementType.IMAGE,
    //   ),
    //   new FormElement(
    //     'driversLicense.back',
    //     'I.D./drivers license (Back)',
    //     new FormElementOptions(),
    //     '',
    //     FormElementType.IMAGE,
    //   ),
    // ],
    [
      new FormElement(
        'password',
        'Password',
        new FormElementOptions(),
        'required',
        FormElementType.PASSWORD,
      ),
      new FormElement(
        'passwordConfirmation',
        'Password confirmation',
        new FormElementOptions(),
        'required',
        FormElementType.PASSWORD,
      ),
    ],
    [
      new FormElement(
        'isChecked',
        'I accept the <a target="_blank" href="/files/Tapazz_TermsAndConditions_200720.docx.pdf">terms & conditions</a> and <a target="_blank" href="/files/Tapazz Privacy Verklaring Platform 20200601.docx.pdf">privacy policy</a>',
        new FormElementOptions(),
        'required',
        FormElementType.CHECKBOX,
      ),
    ],
  ];

  @appModule.Getter('isLoading')
  isLoading!: (id: string) => boolean;

  @appModule.Mutation('addLoader')
  addLoader!: (id: string) => void;

  @appModule.Mutation('removeLoader')
  removeLoader!: (id: string) => void;

  @appModule.Mutation('addToast')
  addToast!: (toast: Toast) => void;

  @authModule.Action('login')
  login!: (login: Login) => Promise<LoginResult>;

  @authModule.Action('register')
  register!: (registration: Registration) => Promise<User>;

  @authModule.Action('findByInviteToken')
  findByInviteToken!: (token: string) => Promise<User>;

  async doRegistration() {
    this.addLoader('registration');
    try {
      if (!this.registration.isChecked) {
        this.showErrorForTermsAndConditions = true;
        return;
      }
      const user = await this.register(this.registration);
      this.addToast(
        new Toast(
          this.$t('registration-complete') as string,
          ToastType.SUCCESS,
        ),
      );
      if (user.role === Role.User) {
        this.$router.push({ name: 'DownloadApp' });
        return;
      }
      this.addToast(new Toast(this.$t('logging-in') as string, ToastType.INFO));
      this.loginData = new Login(user.email, this.registration.password);
      const loggedIn = await this.login(this.loginData);
      if (!loggedIn) {
        return;
      }
      setTimeout(() => {
        this.$router.push({ path: '/' });
        this.removeLoader('registration');
      }, 1000);
    } catch (e) {
      this.addToast(new Toast(e.message, ToastType.ERROR));
    } finally {
      this.removeLoader('registration');
    }
  }

  async validateInviteToken(token: string) {
    this.user = await this.findByInviteToken(token);
    if (!this.user) {
      this.error = true;
      this.addToast(
        new Toast('No user found for invite token', ToastType.ERROR),
      );
    }
    this.registration.email = this.user.email;
  }

  async created() {
    this.registration.token = this.$route.params.token;
    this.validateInviteToken(this.registration.token);
  }
}
